<template>
  <responsive-menu
    content-class="py-0"
    nudge-bottom=4
    v-model="isMenuOpen"
  >
    <template v-slot:activator="{ on }">
      <v-chip
        data-cy="pinned-condition"
        v-on="on"
        class="pinned-condition"
        :close="!unlock && isFilterActive"
        :close-icon="$icon('i.ClearList')"
        :color="chipColor"
        @click:close="clearConditions"
      >
        <div class="d-flex flex-column">
          <div class="subtitle-2 text-truncate max-width-text d-inline-block">
            {{displayText}}
          </div>
          <div class="subtitle-2 text-truncate max-width-text d-inline-block">
            {{displayChipValue}}
          </div>
        </div>
        <toggle-consolidated
          class="ml-1"
          :style="{'background-color': isFilterActive ? 'revert' : undefined}"
          :consolidable="consolidable"
          :consolidated.sync="conds[0].consolidated"
          readonly
        />
        <v-spacer />
        <pinned-btn
          v-if="unlock"
          @click="unpinConditions()"
          is-pinned
          :is-selected="isFilterActive"
        />
      </v-chip>
    </template>
    <component
      v-for="(cond, index) in conds"
      :key="index"
      @add-cond="addCond(cond)"
      @delete-cond="deleteCond(cond)"
      @set-values="setConditionValues(cond, $event)"
      @close="isMenuOpen = false"
      :cond="cond"
      :index="index"
      :is="cond.comparand.valTy"
      :is-menu-open="isMenuOpen"
      :is-last='index === conds.length-1'
      :value="cond.comparand.c"
    />
  </responsive-menu>
</template>

<script>
import ClientCache from '@/mixins/client-cache'
import Search from '@/pages/search/controllers'

export default {
  components: {
    Date: () => import('./condition/condition-date'),
    Number: () => import('./condition/condition-number'),
    String: () => import('./condition/condition-string'),
    PinnedBtn: () => import('./pinned-btn'),
    ResponsiveMenu: () => import('@/pages/search/components/responsive-menu'),
    ToggleConsolidated: () => import('@/components/toggle-consolidated')
  },
  computed: {
    cacheType () {
      return ClientCache.CacheType.ColumnWithParams
    },
    chipColor () {
      if (this.isFilterActive) { return 'primary' }
      return this.$vuetify.theme.isDark ? undefined : 'white'
    },
    consolidable () {
      return this.lodash.isBoolean(this.cache?.doc?.consolidated)
    },
    displayChipValue () {
      const conds = this.conds.filter(c => c.isActive)
      let text = conds[0]?.displayText(this.cache?.doc?.currency)
      if (conds.length > 1) {
        text += ` (+${conds.length - 1})`
      }

      return text
    },
    displayText () {
      return this.cache?.doc?.name
    },
    id () {
      const { col, consolidated = null, currency = null } = this.conds[0]
      return [col, consolidated, currency]
    },
    isConsolidated () {
      return this.cache?.doc?.consolidated
    },
    isFilterActive () {
      return this.conds.some(cond => cond.isActive)
    }
  },
  data () {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    addCond (cond) {
      this.search.pinCondition(
        {
          consolidated: cond.consolidated,
          currency: cond.currency,
          id: cond.col,
          text: cond.name
        },
        cond.ops,
        cond.comparand.valTy)
    },
    deleteCond (cond) {
      this.search.unpinCondition(cond)
      this.search.setFilterSet(this.search.filterSet).execute()
    },
    clearConditions () {
      this.conds.forEach((cond, index) => {
        if (index === 0) {
          cond.setValues()
        } else {
          this.search.filterSet.removeCondition(cond)
        }
      })
      this.search.setFilterSet(this.search.filterSet).execute()
    },
    setConditionValues (cond, values) {
      if (this.lodash.isEqual(values, cond.comparand.c)) { return }
      cond.setValues(values)
      this.search.setFilterSet(this.search.filterSet).execute()
    },
    unpinConditions () {
      this.conds.forEach(cond => this.search.filterSet.removeCondition(cond))
      this.search.setFilterSet(this.search.filterSet).execute()
    }
  },
  mixins: [ClientCache],
  props: {
    conds: Array,
    search: Search,
    unlock: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.pinned-condition
  min-height 42px
  min-width 100px

  >>>.v-chip__content
    width 100%

.max-width-text
  max-width 250px
</style>
